<footer class="footer-area footer--light">
  <!-- <div class="diagnoal footer-diagonal d-none d-md-block" style="border-color: #f7f7f7 transparent #f7f7f7 transparent;"></div> -->

  <div class="footer-big">
    <!-- start .container -->
    <div class="container">
      <div class="row">
        <div class="footer-widget" style="margin-bottom: 0;">
          <div class="widget-about" style="margin-bottom: 18px;">
            <img src="../../../../assets/imgs/Logo.svg" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 footer-widget text-left">
          <p class="small" style="margin-top: 15px;">{{ "footerDescription" | translate }}</p>
        </div>
        <div class="col-md-3 col-9">
          <div class="footer-widget text-left">
            <div class="footer-menu footer-menu--1">
              <h4 class="footer-widget-title">{{ "about" | translate }}</h4>
              <ul>
                <li>
                  <a [routerLink]="this.base.getrouterlink('/about')">{{
                    "about" | translate
                    }}</a>
                </li>
                <li>
                  <a [routerLink]="this.base.getrouterlink('/privacy')">
                    {{ "privacy policy" | translate }}</a>
                </li>

                <li>
                  <a [routerLink]="this.base.getrouterlink('/terms')">
                    {{ "terms conditions" | translate }}</a>
                </li>
                <li>
                  <a [routerLink]="this.base.getrouterlink('/contact')">{{
                    "contact us" | translate
                    }}</a>
                </li>
              </ul>
            </div>
            <!-- end /.footer-menu -->
          </div>
          <!-- Ends: .footer-widget -->
        </div>
        <!-- end /.col-md-3 -->

        <div class="col-md-3 col-12">
          <div class="footer-widget text-left">
            <div class="footer-menu">
              <h4 class="footer-widget-title">
                {{ "our services" | translate }}
              </h4>
              <ul>
                <li>
                  <a [routerLink]="this.base.getrouterlink('/add-property')">
                    {{ "List Property" | translate }}</a>
                </li>
                <li>
                  <a class="pointer" (click)="searchByType('sale')">
                    {{ "Sell Real Estate" | translate }}</a>
                </li>
                <li>
                  <a class="pointer" (click)="searchByType('rent')">
                    {{ "Rent Real Estate" | translate }}</a>
                </li>
                <li>
                  <a class="pointer" (click)="searchByType('shared')">
                    {{ "Student Housing" | translate }}</a>
                </li>
              </ul>
            </div>
            <!-- end /.footer-menu -->
          </div>
          <!-- Ends: .footer-widget -->
        </div>
        <!-- end /.col-lg-3 -->

        <div class="col-md-3 col-sm-4 col-12">
          <div class="footer-widget text-left">
            <div class="footer-menu">
              <h4 class="footer-widget-title">
                {{ "Help Support" | translate }}
              </h4>
              <ul class="list-inline social-icon">
                <li class="list-inline-item" *ngFor="let item of socialList">
                  <a [href]="item.social_url" [target]="item.social_target">
                    <img class="img-fluid icon" [src]="item.social_image | media" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <!-- end /.footer-menu -->
          </div>
          <!-- Ends: .footer-widget -->
        </div>
        <!-- Ends: .col-lg-3 -->
      </div>
      <!-- end /.row -->
    </div>
    <!-- end /.container -->
  </div>
  <!-- end /.footer-big -->

  <div class="mini-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="copyright-text">
            <p>
              {{ "All rights reserved" | translate }}
              <a href="#" class="text-white">{{ "Msaakn" | translate }}</a>
              2021
              {{ "Developed-By" | translate }}
              <a href="#" class="text-white">{{
                "Approved-Systems" | translate
                }}</a>
            </p>
          </div>

          <div class="go_top pointer" *ngIf="isShow" (click)="gotoTop()">
            <i class="fas fa-chevron-up"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>