import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { SidebarModule } from 'primeng/sidebar';


const COMPONENTS = [
  SidebarComponent
];

@NgModule({
  imports: [
    CommonModule,
    SidebarModule
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class SideBarCustomModule { }
