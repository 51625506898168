import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from './notification-list.component';
import { RouterModule, Routes } from '@angular/router';
import { AppSharedModule } from '../app.shared.module';


const routes: Routes = [
  {
    path: '',
    component: NotificationListComponent
  }
]

@NgModule({
  declarations: [NotificationListComponent],
  imports: [
    CommonModule,
    AppSharedModule,
    RouterModule.forChild(routes)
  ], exports: [
    NotificationListComponent
  ]
})
export class NotificationListModule { }
