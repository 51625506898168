import { Injectable } from '@angular/core';
import { CoreService } from '../base/core.service';

@Injectable({
  providedIn: 'root'
})
export class LocaleService extends CoreService {

  constructor() {
    super();
   }

  public localear: ILocale = { code: locals.ar, text: 'العربية', Url: 'ar' };
  public localeen: ILocale = { code: locals.en, text: 'English', Url: 'en' };

  getlocalbykey(key: string, local) {
      try {
          if (local == 'en') {
              return this.Stringlocalization[key].valen;

          } else {
              return this.Stringlocalization[key].valar;

          }
      } catch (e) {
          // this.log(e, 'LocaleService');
          // this.log('key= ' + key, 'LocaleService');
          return '';
      }
  }
  Stringlocalization: { [id: string]: ILocalString; } = {
    'Home': { valen: 'Home', valar: 'الرئيسية' },
    'MyAccount': { valen: 'My Account', valar: 'حسابي' },
    'IdentityNo': { valen: 'Identity Number', valar: 'رقم الهوية' },
    //login Page
    'Password': { valen: 'Password', valar: ' كلمة السر' },
    'Login': { valen: 'Login', valar: '  تسجيل الدخول' },
    'Register': { valen: 'Register as new user', valar: '  تسجيل مستخدم جديد' },
    'ForgetPassword': { valen: 'Forget Password?', valar: '    نسيت كلمة السر؟' },
  }
}

export enum locals {
  en = 'en',
  ar = 'ar'
}

export interface ILocalString {
  valar: string,
  valen: string,
}

export interface ILocale {
  code: string;
  text: string;
  Url: string;
}

