import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { TranslateModule } from '@ngx-translate/core';
import { MediaPipeModule } from '../../../services/pipes/media/media.module';


const COMPONENTS = [
    FooterComponent
];
@NgModule({

    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        AccordionModule,
        MediaPipeModule
    ],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS]

})
export class FooterModule { }
