import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { ToastrModule } from 'ngx-toastr';
import { DialogModule } from 'primeng/dialog';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { ChipsModule } from 'primeng/chips';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { SidebarModule } from 'primeng/sidebar';
import { AccordionModule } from 'primeng/accordion';
import { FileUploadModule } from 'primeng/fileupload';
import { CarouselModule } from 'primeng/carousel';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule as NgxCarouselModule } from "ngx-owl-carousel-o";
import { RatingModule } from 'primeng/rating';
import { TranslateModule } from '@ngx-translate/core';
import { SearchComponent } from './shared/component/search/search.component';
import { EncryptHelper } from './services/base/encrypthelper.service';
import { PaginatorModule } from 'primeng/paginator';
import { MapsComponent } from './shared/component/maps/maps.component';
import { RouterModule } from '@angular/router';
import { RequiredPropSearchComponent } from './shared/component/required-prop-search/required-prop-search.component';
import { AuthDirective } from './services/pipes/auth.directive';
import { NotificationComponent } from './shared/component/notification/notification.component';
import { GalleriaModule } from 'primeng/galleria';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [AuthDirective, SearchComponent,
    MapsComponent, RequiredPropSearchComponent, NotificationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    PaginatorModule,
    CalendarModule,
    DialogModule,
    SidebarModule,
    CarouselModule,
    NgxCarouselModule,
    SliderModule,
    ConfirmDialogModule,
    TranslateModule,
    FileUploadModule,
    AccordionModule,
    ChipsModule,
    GalleriaModule,
    SkeletonModule,
    // PdfViewerModule,
    TabViewModule, TableModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      closeButton: true,
      // toastClass: 'text-toastr',

    }),
  ],
  providers: [EncryptHelper],
  exports: [
    RouterModule,
    SliderModule,
    PaginatorModule,
    CarouselModule,
    ConfirmDialogModule,
    NgxCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CalendarModule,
    TranslateModule,
    GoogleMapsModule,
    DialogModule,
    FileUploadModule,
    SidebarModule,
    RequiredPropSearchComponent,
    AccordionModule,
    SearchComponent,
    RatingModule,
    GalleriaModule,
    SkeletonModule,
    TabViewModule, MapsComponent, ChipsModule, TableModule,
    AuthDirective, NotificationComponent

  ],
})
export class AppSharedModule { }
