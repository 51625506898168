<div [ngClass]="notificationList.length > 0?'container':''">
    <div  [ngClass]="notificationList.length > 0?'row':''">
        <div class="bg-white col-12 row mx-0 px-0" *ngIf="notificationList.length == 0">
            <div class="box-body text-muted p-0 row col-12 mx-0 ">
                <div class="align-items-center mt-5 text-center col-md-12 col-lg-12 col-12">
                <p><i class="fa fa-bell fa-10x" style="opacity: 0.2;"></i></p>
                <p><span>{{"There are no notifications"|translate}}</span></p>
                </div>
            </div>
        </div>
        <div class="box rounded bg-white mb-3 col-12 row mx-0 px-0" *ngIf="notificationList.length > 0">
            <div class="box-title border-bottom p-3 text-left row mx-0 col-12">
                <h6 class=" col-12 col-md-6" *ngIf="hide">{{"Notifications"|translate}}</h6>
                <a class=" pointer col-12 text-right" [class]="hide?'col-md-6':'col-md-12 small'"
                    *ngIf="notificationList.length>0" (click)="allNotification(notifStatus)">
                    <u>{{notifStatus |
                    translate}}</u>
                </a>
            </div>
            <div class="box-body p-0 row col-12 mx-0">
                <ng-container *ngFor="let item of notificationList">
                <div class="p-3 d-flex align-items-center  border-bottom col-12 mx-0"
                    [class]="item.seen_type=='unseen'?'bg-light':'bg-white'">
                    <div class="font-weight-bold  text-left">
                        <a class="float-left ml-2 pointer" (click)="notificationStatus(item)">
                            <i class=" fa-circle" [class]="item.seen_type=='unseen'?'fas brand-color':'far'"></i>
                        </a>
                        <div class="small float-left ">
                            {{item.message}}
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>