import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input('IsVisable') IsVisable: boolean = false;
  @Input('fullScreen') fullScreen: boolean = false;
  @Input('position') position: string = '';
  @Input('userStyle') userStyle: string = '';
  @Input('cssClass') cssClass: string = '';
  @Input('showCloseIcon') showCloseIcon: boolean;// = !this.base.isMobile();
  @Output() IsVisableChanged = new EventEmitter();

  constructor() { }
  onShow() {
    try {
      let body = document.getElementsByTagName('body')[0];
      body.classList.add("body-no-scroll");
      let warp = document.getElementById('warp');
      warp.classList.add("body-no-scroll");
    } catch (error) {

    }

  }

  onHide() {
    try {
      let body = document.getElementsByTagName('body')[0];
      body.classList.remove("body-no-scroll");
      let warp = document.getElementById('warp');
      warp.classList.remove("body-no-scroll");
    } catch (error) {

    }

  }

  hideSidebar() {
    this.IsVisable = false;
    this.IsVisableChanged.emit(this.IsVisable);
  }

  ngOnInit(): void {
    
  }

}
