<!-- start navbar-->
<div class="activate-mobile" *ngIf="
    (ProfileInfo?.need_verify_mobile == true && authService.isLoggedIn()) ||
    (ProfileInfo?.need_verify_email == true && authService.isLoggedIn())
  ">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center py-1">
        <h3 class="h6 text-white">
          <span *ngIf="ProfileInfo?.need_verify_mobile == true">
            {{ "mobile_need_to_verify" | translate }},
            <a [routerLink]="this.base.getrouterlink('/verify-phone')" class="text-white">
              <u>{{ "active_now" | translate }}</u>
            </a>
            |
          </span>
          <span *ngIf="ProfileInfo?.need_verify_email == true">
            {{ "email_need_to_verify" | translate }},
            <a [routerLink]="this.base.getrouterlink('/verify-email')" class="text-white">
              <u>{{ "active_now" | translate }}</u>
            </a>
          </span>
        </h3>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light custom-navbar">
  <div class="container">
    <a class="navbar-brand" [routerLink]="base.getrouterlink('')"><img class="img-fluid"
        src="../../../../assets/imgs/Logo.svg" alt="" /></a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse text-left" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item active-link">
          <a class="nav-link text-capitalize" [routerLink]="base.getrouterlink('/')">{{ "Home" | translate }}</a>
        </li>

        <!-- realestate  -->

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{ "Real Estate" | translate }}
          </a>
          <div class="dropdown-menu dropdown-individual" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="base.getrouterlink('/add-property')">
              {{ "List Property" | translate }}
            </a>

            <a class="dropdown-item pointer" (click)="searchByType('sale')">
              {{ "Sell Real Estate" | translate }}
            </a>

            <a class="dropdown-item pointer" (click)="searchByType('rent')">
              {{ "Rent Real Estate" | translate }}
            </a>

            <a class="dropdown-item pointer" (click)="searchByType('shared')">
              {{ "Student Housing" | translate }}</a>

            <a class="dropdown-item" [routerLink]="base.getrouterlink('/required')">
              {{ "Wanted Properties" | translate }}
            </a>

            <a class="dropdown-item pointer" [routerLink]="base.getrouterlink('/nearest')">
              {{ "nearest notary contracts" | translate }}
            </a>
          </div>
        </li>
        <li class="nav-item d-sm-block">
          <a class="nav-link text-capitalize btn btn-white" [routerLink]="base.getrouterlink('/shorts')">
            {{ "rent for a short period" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" [routerLink]="base.getrouterlink('/companies')">{{ "Companies" | translate
            }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" [routerLink]="base.getrouterlink('/contractors')">{{ "constructors" |
            translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" [routerLink]="base.getrouterlink('/search')">{{ "search" | translate
            }}</a>
        </li>
        <li class="nav-item dropdown" *ngIf="this.authService.isLoggedIn()">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{ "My Account" | translate }}
          </a>
          <div class="dropdown-menu dropdown-individual account-dropdown" aria-labelledby="user_dropdown">
            <div class="user-dropdown d-none d-md-flex">
              <img [src]="base.imgUrI + ProfileInfo?.personal_photo" *ngIf="ProfileInfo?.personal_photo != undefined"
                class="img-fluid rounded-circle mr-2" alt="" />
              <img src="../../../../assets/imgs/user.png" *ngIf="ProfileInfo?.personal_photo == undefined"
                class="img-fluid rounded-circle mr-2" alt="" />
              <div class="account-name">
                <h5 class="user-name">{{ ProfileInfo?.name }}</h5>
              </div>
            </div>
            <a class="dropdown-item" [routerLink]="base.getrouterlink('/account/settings')">
              <span class="li-icon fas fa-cogs mr-2"></span>{{ "Account Settings" | translate }}</a>
            <a class="dropdown-item" [routerLink]="base.getrouterlink('/account/ads')"><span
                class="li-icon fas fa-warehouse mr-2"></span>{{ "My Ads" | translate }}
            </a>

            <a class="dropdown-item" [routerLink]="base.getrouterlink('/account/saved')">
              <span class="li-icon fas fa-save mr-2"></span>{{ "Saved Property" | translate }}</a>
            <a class="dropdown-item" [routerLink]="base.getrouterlink('/account/request')"><span
                class="li-icon fas fa-paper-plane mr-2"></span>{{ "Sent Requests" | translate }}
            </a>
            <a class="dropdown-item" *ngIf="ProfileInfo?.account_type == 'company'"
              [routerLink]="base.getrouterlink('/account/employees')"><span
                class="li-icon fas fa-user-cog mr-2"></span>{{ "Employees Management" | translate }}
            </a>
            <a class="dropdown-item" [routerLink]="base.getrouterlink('/account/my-bookings')"><span
                class="li-icon fas fa-user-cog mr-2"></span>{{ "My bookings" | translate }}
            </a>
            <a class="dropdown-item logout-btn pointer" (click)="logout()"><i
                class="li-icon fas fa-sign-out-alt mr-2"></i>{{ "Logout" | translate }}
            </a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li *ngIf="!this.authService.isLoggedIn()" class="nav-item d-sm-block d-md-none">
          <a class="nav-link" #loginbtn (click)="showLogin()">{{
            "Login" | translate
            }}</a>
        </li>
        <li *ngIf="!this.authService.isLoggedIn()" class="nav-item d-sm-block d-none">
          <a class="nav-link text-capitalize btn btn-white" #loginbtn (click)="showLogin()">{{ "Login" | translate }}</a>
        </li>
        <li class="nav-item d-sm-block d-md-none" *ngIf="this.authService.isLoggedIn()">
          <a [routerLink]="this.base.getrouterlink('/notification')">
            {{ "Notifications" | translate }}
          </a>
        </li>
        <li class="nav-item d-sm-block d-none" *ngIf="
            this.authService.isLoggedIn() &&
            !this.router.url.includes('notification')
          ">
          <div class="btn-group dropleft notify nav-link" role="group">
            <button class="btn gray-color p-0 dropdown-toggle dropdown-toggle-split" id="notify" type="button"
              data-toggle="dropdown">
              <i class="fas fa-bell text-muted"></i><span  *ngIf="count > 0" class="badge badge-secondary">{{ count }}</span>
            </button>
            <ul class="
                dropdown-menu dropdown-menu-right
                notify-drop
                notification-dropdown
              " (click)="$event.stopPropagation()">

              <app-notification-list (count)="getCount($event)"></app-notification-list>
              <div class="notify-drop-footer text-center" *ngIf="count > 0">
                <a class="mb-3" [routerLink]="this.base.getrouterlink('/notification')">
                  <i class="fa fa-eye"></i>
                  {{ "Show All" | translate }}
                </a>
              </div>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <ng-container *ngFor="let lang of translate.getLangs()">
            <a *ngIf="lang == selectedLang" class="p-0" type="button" [href]="lang | durl: true">
              <ng-container *ngIf="router.url.includes('/en')">
                <span class="nav-link">العربية</span>
              </ng-container>
              <ng-container *ngIf="router.url.includes('/ar')">
                <span class="nav-link">English</span>
              </ng-container>
            </a>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- end navbar-->
<app-sidebar [cssClass]="'custom-sidebar'" (IsVisableChanged)="displayLogin = $event" [(IsVisable)]="displayLogin"
  showCloseIcon="false" [userStyle]="{ width: '25em' }" [position]="base.isMobile() ? 'bottom' : 'left'">
  <div class="row col-12 p-0 m-0">
    <div class="col-12 sidebar-header py-2">
      <span class="float-left">
        <b class="h5 font-weight-bold">{{ "Login" | translate }}</b>
      </span>
      <span class="float-right">
        <a class="pointer" (click)="displayLogin = false">
          <i class="far fa-times mr-2 headercolor h6 font-weight-bold"></i>
        </a>
      </span>
    </div>
    <div class="col-12 mt-4">
      <app-shared-login (showSidebar)="closeLogin($event)"></app-shared-login>
    </div>
  </div>
</app-sidebar>