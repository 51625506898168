import { Injectable, Inject, Injector } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Params, ActivatedRoute } from "@angular/router";
import { ServerMode, FLWHttpHeaders } from "../models/enums";
import { Buffer } from "buffer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ServerOptionService } from "../base/server-option.service";
import { StorageService } from "../base/storage.service";

@Injectable()
export class APIService {
  _apiURI: string;
  _serverMode: ServerMode = ServerMode.Local;
  _headeroptions: HttpHeaders;
  _headeroptionsFiles: HttpHeaders;
  _accessToken = "";

  appversion = "1.0.0";

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private serop: ServerOptionService,
    private acroute: ActivatedRoute
  ) {
    serop.setServerMode();
    this.setAPiSettings(APIVS.V1);
    this.setaccessToken();
  }

  setAPiSettings(apivs: APIVS = APIVS.V1) {
    this.serop.setServerMode();
    this.setaccessToken();

    if (this.serop._baseurl.toLowerCase().indexOf("localhost") > 0) {
      this._apiURI = APILink.localv1;
      this._serverMode = ServerMode.Local;
    } else if (
      this.serop._baseurl.toLowerCase().indexOf("sandbox") > 0 ||
      this.serop._baseurl.toLowerCase().indexOf("beta") > 0
    ) {
      this._apiURI = APILink.betav1;
      this._serverMode = ServerMode.Beta;
    } else {
      this._apiURI = APILink.livev1;
      this._serverMode = ServerMode.Live;
    }
  }

  setaccessToken() {
    this._accessToken = this.storage.getAuthToken();
  }

  gethttpParms(params: Params): HttpParams {
    let paramsback: HttpParams = new HttpParams();
    if (params) {
      for (let property in params) {
        if (params.hasOwnProperty(property)) {
          paramsback = paramsback.append(property, params[property]);
        }
      }
    }

    return paramsback;
  }

  getFormData(parms: any): FormData {
    var form_data = new FormData();
    for (var key in parms) {
      form_data.append(key, parms[key]);
    }
    return form_data;
  }

  buildheaders(apivs: APIVS) {
    this.setAPiSettings(apivs);
    this.setaccessToken();
    let device = "2";
    let appvs = this.appversion;
    if (this.acroute.snapshot.queryParams.device) {
      device = this.acroute.snapshot.queryParams.device;
    }
    if (this.acroute.snapshot.queryParams.appversion) {
      appvs = this.acroute.snapshot.queryParams.appversion;
    }
  }

  build(method: Apimethods, ...extrereplace: string[]) {
    let methodstring = method.toString();
    if (extrereplace.length > 0) {
      methodstring = FormatString(methodstring, ...extrereplace);
    }
    return `${this._apiURI}${methodstring}`;
  }

  get<T>(
    apivs: APIVS,
    method: Apimethods,
    parms: any,
    ...extrereplace: string[]
  ): Observable<T> {
    this.buildheaders(apivs);
    const options = {
      // headers: this._headeroptions,
      params: this.gethttpParms(parms),
    };
    return this.http.get<T>(this.build(method, ...extrereplace), options).pipe(
      map((data: T) => {
        return data;
      })
    );
  }

  post<T>(
    apivs: APIVS,
    method: Apimethods,
    isFormData: boolean,
    parms: any,
    ...extrereplace: string[]
  ): Observable<T> {
    this.buildheaders(apivs);
    const options = {
      // headers: this._headeroptions
       reportProgress: true,
        
    };
    const body = isFormData ? this.getFormData(parms) : JSON.stringify(parms);
    return this.http
      .post<T>(this.build(method, ...extrereplace), body, options)
      .pipe(
        map((data: T) => {
          return data;
        })
      );
  }

  delete<T>(
    apivs: APIVS,
    method: Apimethods,
    parms: any,
    ...extrereplace: string[]
  ): Observable<T> {
    this.buildheaders(apivs);
    const options = {
      // headers: this._headeroptions,
      params: this.gethttpParms(parms),
    };
    const body = JSON.stringify(parms);
    return this.http
      .delete<T>(this.build(method, ...extrereplace), options)
      .pipe(
        map((data: T) => {
          return data;
        })
      );
  }

  put<T>(
    apivs: APIVS,
    method: Apimethods,
    isFormData: boolean,
    parms: any,
    ...extrereplace: string[]
  ): Observable<T> {
    this.buildheaders(apivs);
    const options = {
      // headers: this._headeroptions
      params: this.gethttpParms(parms),
    };
    const body = isFormData ? this.getFormData(parms) : JSON.stringify(parms);
    return this.http
      .put<T>(this.build(method, ...extrereplace), body, options)
      .pipe(
        map((data: T) => {
          return data;
        })
      );
  }
}

export enum APILink {
  livev1 = "https://api.msaakn.com/api/v1/",
  betav1 = "https://api.msaakn.com/api/v1/",
  localv1 = "https://api.msaakn.com/api/v1/",
}

export enum APIVS {
  V1 = 1,
}

export enum Apimethods {
  // property api
  home = "latest/realestates",
  countries = "countries",
  cities = "cities",
  districts = "districts/{0}",
  propertyType = "property/types",
  propertyExt = "property/extensions",
  search = "realestate",
  propById = "realestate/{0}",
  shortById = "short/room/{0}",
  shortrealestatesById = "short/realestates/{0}",
  required = "required/realestate",
  requiredById = "required/realestate/{0}",
  checkavilablepost = "my/realestate/create",
  addToSave = "favorites",
  removeFromSave = "favorites/{0}",
  step1 = "my/realestate/steps",
  step2 = "my/realestate/steps",
  step3 = "my/realestate/steps",
  step4 = "create/property/step/4",
  step5 = "my/realestate/steps",
  updateRoom = "my/realestate/update/room",
  // getPropertyEdit = "my/realestate/create",

  matchPrice = "match/price/{0}",
  UploadMedia = "my/realestates/upload/media",
  DeleteMedia = "my/realestates/delete",
  AddDirections = "store/property/directions",
  savedSearch = "searches",
  deleteSavedSearch = "search/delete/{0}",
  AdvancedSearchResult = "search/result",
  AdvancedSearchSave = "searches",
  Roomextensions = "room/extension",

  //account
  manageEmployee = "my/realestate",
  urgentRequests = "urgentrequests",
  getEmployee = "employees",
  statistics = "statistics",
  updateEmployee = "employees/{0}",
  deleteEmployee = "employees/{0}",
  AddEmployee = "employees",
  requests = "realestate/all/request",
  myProperty = "my/realestate",
  deleteProperty = "my/realestate/{0}",
  // company
  companies = "companies",
  nearestCompany = "nearby/notaries",
  savedProperty = "favorites",
  comapnyProfile = "companies/{0}",
  profileRate = "companies/{0}",
  CompanyPro = "companies/{0}",
  getCustomers = "customers",
  updateCustomer = "customers/{0}",
  AddCustomer = "customers",
  DeleteCustomer = "customers/{0}",
  AddRequest = "urgentrequests",
  UpdateRequest = "urgentrequests/{0}",
  DeleteRequest = "urgentrequests/{0}",

  showProRequstes = "realestate/all/request",
  acceptSellRequest = "account/order/accept/sell/{0}",
  acceptRentRequest = "account/order/accept/rent/{0}",
  acceptStudentRequest = "account/order/accept/student/{0}",

  refuseSelltRequest = "account/order/refused/sell/{0}",
  refuseRentRequest = "account/order/refused/rent/{0}",
  refuseStudentRequest = "account/order/refused/student/{0}",

  processSellRequest = "account/order/processing/sell/{0}",
  processRentRequest = "account/order/rent/sell/{0}",
  processStudentRequest = "account/order/student/sell/{0}",

  cancelPurchase = "account/orders/cancel/sell/request/{0}",
  cancelRent = "account/orders/cancel/rent/request/{0}",
  cancelStudent = "account/orders/cancel/student/request/{0}",
  // reserve
  purchaseProperty = "purchase/property/sell/{0}",
  rentProperty = "reserve/property/rent/{0}",
  reserveRoom = "realestate/request",

  // environment
  Login = "login",
  register = "register",
  logout = "logout",
  pages = "page/{0}",
  privacy = "privacy/policy",
  contactUs = "contactus",
  profile = "user",
  resetPassword = "forget/password",
  resetCode = "reset/password",
  updatePhoto = "account/update/photo",
  accountUpdate = "update/account",
  changePassword = "change/password",
  notification = "notifications",
  notificationStatus = "notifications",
  allNotification = "notifications/seen/all",
  verifyPhoneCode = "resend/code/mobile",
  verifyEmailCode = "resend/code/email",
  code = "verify/code",
  contractors = "contractors",
  contractorsCreate = "contractors/create",
  socialLinks = "social",
  bookingroom = "booking",
  addrealestate = "add/your/realestate",
  mybooking = "get/my/booking",
  cancelbooking = "cancel/booking/{0}",
  acceptbooking = "booking/accept",
  listShortRooms = "short/rooms",
  listShortRealestates = "short/realestates",

  // cities = 'get/cities',
}

export function FormatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}
