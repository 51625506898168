<form (ngSubmit)="login()" [formGroup]="loginForm" class="row col-12  p-0 m-0 sidebar-body ">
    <div class="field-group col-12">
        <input name="email" autocomplete="email" type="email" formControlName="account" required>
        <label>{{"email_mobile"|translate}}</label>
        <span *ngIf="loginForm.controls['account'].invalid && (loginForm.controls['account'].dirty || loginForm.controls['account'].touched)" class="text-danger text-left d-block">
            <span *ngIf="loginForm.controls['account'].errors.required">
                {{"enter_email_mobile"|translate}}
            </span>
        </span>
    </div>
    <div class="field-group col-12 mb-0">
        <a class="show-password pointer" (click)="showPass==true?showPass=false:showPass=true"><i
        [class]='showPass==true?"fas fa-eye-slash":"fas fa-eye"'></i></a>
        <input name="password" autocomplete="current-password" type="password" formControlName="password"
        [type]='showPass==true?"text":"password"' required>
        <label>{{"password"|translate}}</label>
        <span *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)" class="text-danger d-block text-left">
            <span *ngIf="loginForm.controls['password'].errors.required">
                {{"password"|translate}}
            </span>
        </span>
    </div>
    <div class="col-12 mt-2">
        <a class="pointer small text-dark" [href]="'reset'|durl">
            {{"Forget Password?"|translate}}
        </a>
    </div>
    <div class="col-12 col-md-6 mt-3">
        <button class="btn btn-brand w-100" type="submit" [class.loginbtn]="loginbtn">{{'Login'|translate}}</button>
        <center>
            <span [class.spinner]="loading"></span>
        </center>
        <!-- [disabled]="!loginForm.valid" -->
    </div>
    <div class="col-12 col-md-6 mt-3">
        <a class="btn btn-white w-100" [href]="'register'|durl">{{'register'|translate}}</a>
    </div>
</form>