import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/api/auth.service';
import { BaseService } from '../services/base/base.service';
import { ServerOptionService } from '../services/base/server-option.service';
import { APIResult, notificationModule, PaginationResponse } from '../services/models/models';


@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  notificationList: notificationModule[] = [];
  cuurentpage: number = 1;
  notifStatus: string = 'Mark all as read';
  hide: boolean = false;
  @Output() count = new EventEmitter<any>();
  constructor(private auth: AuthService, private router: Router,public base: BaseService, public serop: ServerOptionService) {

  }

  ngOnInit(): void {
    this.getNotification()
    if (this.router.url.includes('notification')) {
      this.hide = true;
    }
    else {
      this.hide = false
    }
  }
  getNotification() {
    this.auth.GetNotification(this.cuurentpage, this.serop._baseurl.includes('/ar') ? "ar" : "en").subscribe((da: APIResult<PaginationResponse<notificationModule[]>>) => {
      this.notificationList = da.notifications.data;
      this.count.emit(da.unseen_count)
    })
  }
  //notification status 
  notificationStatus(item: notificationModule) {
    if (item.seen_type == 'seen') {
      this.auth.notificationStatus(item.id, 'unseen').subscribe((res) => {
        this.getNotification()
      })
    } else {
      this.auth.notificationStatus(item.id, 'seen').subscribe((res) => {
        this.getNotification()

      })
    }

  }
  allNotification(type) {
    if (type === 'Mark all as read') {
      this.auth.allNotification('seen_all').subscribe((res) => {
        this.getNotification();
        this.notifStatus = 'Mark all as unread'

      })
    } else {
      this.auth.allNotification('unseen_all').subscribe((res) => {
        this.getNotification();
        this.notifStatus = 'Mark all as read'

      })
    }
  }
}
