import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/api/auth.service';
import { BaseService } from '../../../services/base/base.service';
import { loginData, User } from '../../../services/models/models';
//import { AngularFireMessaging } from '@angular/fire/messaging';
import { StorageID } from 'src/app/services/base/storage.service';

@Component({
  selector: 'app-shared-login',
  templateUrl: './shared-login.component.html',
  styleUrls: ['./shared-login.component.scss']
})
export class SharedLoginComponent implements OnInit {

  subzen: Subscription;
  selectedLang: string = 'en';
  showPass: boolean = false;
  loginForm: FormGroup;
  displayForgetPass: boolean = false;
  fcmId: string = '';
  userInfo: User = new User();
  isLoggedIn = false;
  isLoginFailed = false;
  IsVisable: boolean = true;


  loading = false;
  loginbtn = false;

  @Output('showSidebar') showSidebar = new EventEmitter();

  constructor(public translate: TranslateService, public base: BaseService, public router: Router,
    private formbuilder: FormBuilder, private authService: AuthService, private toastr: ToastrService,
    //private afMessaging: AngularFireMessaging,
  ) {
    this.loginForm = this.formbuilder.group({
      "account": [{ value: '', disabled: false }, [Validators.required, Validators.email]],
      "password": [{ value: '', disabled: false }, Validators.required],
      "fcm_id": [{ value: '' }]
    });
  }
  
  login() {
    this.loading = true;
    this.loginbtn = true;
    this.loginForm.patchValue({
      'fcm_id': this.fcmId
    })

    let result = this.loginForm.getRawValue();

    this.authService.login(result).subscribe(res => {
      this.isLoginFailed = false;
      this.isLoggedIn = true;

      this.loading = false;
      this.loginbtn = false;

      if (res.data?.need_verify_email == true) {
        // this.router.navigate(this.base.getrouterlink("/verify-email"))
        this.showSidebar.emit(false)
      } else {
        this.showSidebar.emit(false)
      }
      const segments = this.router.url.split('/');
      if(segments[2] != undefined && segments[2] == 'register'){
        this.router.navigate(this.base.getrouterlink("/"));
      }

     

    }, (err) => {
     
    this.loading = false;
    this.loginbtn = false;
   if(err && err.error != undefined && err.error.errors != undefined){
      let error = err.error.errors;

      let values = Object.keys(error).map(key => error[key]);
   
      if(values[0] != undefined){
        this.toastr.error(values[0], this.base.translate('error'))
      }else{
        this.toastr.error('Unknown error Please reload the page', this.base.translate('error'))
      }
    }else if(err.error != undefined){
       this.toastr.error(err.error.message, this.base.translate('error'))
    }else{
        this.toastr.error('Unknown error Please reload the page', this.base.translate('error'))
    }
      // if (error?.password) {
      //   this.toastr.error(error?.password[0], this.base.translate('error'))
      // }
      // if (err.error?.message) {
      //   this.toastr.error(err.error?.message, this.base.translate('error'))
      // }

    })
  }

  
  typeVal(val){
    return isNaN(val);
  }



  ngOnInit(): void {
    var data = this.base.store.getTimeStamp<string>(StorageID.fcm_id);

    this.fcmId = data.Data;

  }

}
