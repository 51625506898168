<app-notification *ngIf="base.isLoggedIn()==true"></app-notification>
<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>
 
<p-dialog [showHeader]="false" [visible]="isLoading" modal="true" blockScroll="true" class="flw-loading-container" appendToBody="true" showEffect="pop"
 
  [style]="{width: '170px', minWidth: '150px',height:'150px','overflow':'hidden','background-color':'rgba(0, 0, 0, 0.4); !important'}" [baseZIndex]="10000">
  <div class="flw-loading text-left">
    <br/>
    <br/>
    <div style="background-image: url('/assets/imgs/Logo.svg');background-size: contain;background-repeat: no-repeat;" class="d-block col-12 col-md-12 col-lg-12">
    <div class="spinner-border text-white" style="margin-right: -13px;
    margin-top: 43px;
    width: 27px;
    height: 27px;" role="status"></div> 
    <p  style="margin-top: 28px;" class="text-center">{{"... Loading"|translate}}</p>
    </div>
    <!-- <i class="fa fa-spin fa-spinner fa-3x"></i> -->
    <!-- <div class="logo"></div> -->
    <!-- <svg class="spinner" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
    </svg> -->
  </div>
</p-dialog>