import { CommonModule } from "@angular/common";
import { NgModule,Pipe } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarModule } from 'primeng/sidebar';
import { NotificationListModule } from "../../../notification-list/notification-list.module";
import { URLPipeModule } from "../../../../app/services/pipes/URL/href.module";
import { SharedLoginModule } from "../../component/shared-login/shared-login.module";
import { SideBarCustomModule } from "../../component/sidebar/sidebar.module";
import { NavbarComponent } from "./navbar.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        SidebarModule,
        URLPipeModule,
        SharedLoginModule,
        SideBarCustomModule,
        NotificationListModule
    ],
    declarations: [NavbarComponent],
    exports: [NavbarComponent],

})
export class NavbarModule {

  

}
