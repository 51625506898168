// export class LoginInfo {
//     access_token: string;
//     role: string;
// }
export enum ServerMode {
  Local,
  Beta,
  Live
}

export declare type FLWHttpHeaders = {
  "Content-Type": string;
  Accept?: string,
  'Access-Control-Allow-Origin'?: string,
  "Authorization"?: string;
  "lang": string;
  "version": string;
} & {
  [prop: string]: any;
};
