import { TranslateService } from "@ngx-translate/core";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  NavigationEnd,
  NavigationStart,
  Router,
  Scroll,
} from "@angular/router";
import { Subscription } from "rxjs";
import { BaseService } from "./services/base/base.service";
import { loginData } from "./services/models/models";
//import { Messaging as AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMap, mergeMapTo } from "rxjs/operators";
import { NotificationService } from "./services/notification/notification.service";
import { StorageID, StorageService } from "./services/base/storage.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  items: any;
  form: any = {};
  isLoggedIn:boolean = false;
  isLoginFailed = false;
  errorMessage = "";
  roles: string[] = [];
  loginData: loginData = { email: "", password: "", fcm_id: "" };
  subzen: Subscription;
  isarabic: boolean = false;
  selectedLang: string = "ar";
  isLoading: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef,
    public base: BaseService,
    private translate: TranslateService,
    public router: Router,
    //private afMessaging: AngularFireMessaging,
    private notificationService: NotificationService,
    private storage: StorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.isLoading = true;
      }
      if (evt instanceof NavigationEnd) {
        if (this.subzen) this.subzen.unsubscribe();
        let url = evt.urlAfterRedirects.toLowerCase();
        this.base.Browserrefresh = !this.router.navigated;
        if (
          evt.urlAfterRedirects ||
          (url.includes("?") && !url.includes("?id"))
        ) {
          this.base.Browserrefresh = true;
        }

        if (url.includes("/ar")) {
          // this.titleService.setTitle('مساكن');
          this.translate.use("ar");
          this.base.addStyle(true);
        } else {
          this.translate.use("en");
          this.base.addStyle(false);
          // this.titleService.setTitle('Msaakn');
        }
      }
      if (evt instanceof Scroll) {
        this.isLoading = false;
      }
    });
    this.requestPermission();
    this.listen();
  }

  // request permission
  requestPermission() {
    // this.afMessaging.requestToken
    //   .subscribe(
    //     (token) => {
    //       this.storage.setWithTimeStamp<string>(StorageID.fcm_id, token)
    //     },
    //     (error) => { //console.error(error);
    //      },
    //   );
  }
  deleteToken() {
    // this.afMessaging.getToken()
    //   .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
    //   .subscribe(
    //     (token) => {
    //       //console.log('Token deleted!');
    //        },
    //   );
  }
  listen() {
    // this.afMessaging.messages
    //   .subscribe((message: any) => {
    //     this.notificationService.setNotification({
    //       body: message.notification.body,
    //       title: message.notification.title
    //     })
    //   });
  }
  ngAfterViewInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    });
  }
}
