import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { URLPipe } from './href.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [URLPipe],
    exports: [URLPipe],
    providers: [URLPipe]
})
export class URLPipeModule { }