import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  { path: 'about', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'privacy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'contact', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'terms', loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'reset', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'reset-code', loadChildren: () => import('./reset-code/reset-code.module').then(m => m.ResetCodeModule) },
  { path: 'property-list', loadChildren: () => import('./property/property.module').then(m => m.PropertyModule) },
  { path: 'add-property', loadChildren: () => import('./property/add-property/add-property.module').then(m => m.AddPropertyModule) },
  { path: 'edit-property', loadChildren: () => import('./property/add-property/add-property.module').then(m => m.AddPropertyModule) },
  { path: 'companies', loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule) },
  { path: 'contractors', loadChildren: () => import('./contractors/contractors.module').then(m => m.ContractorssModule) },
  { path: 'contractors-login', loadChildren: () => import('./contractors/contractors-login/contractors-login.module').then(m => m.ContractorsLoginModule) },
  { path: 'nearest', loadChildren: () => import('./companies/nearest-notary/nearest-notary.module').then(m => m.NearestNotaryModule) },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule), canActivate: [AuthGuard] },
  { path: 'required', loadChildren: () => import('./demand-property/demand-property.module').then(m => m.DemandPropertyModule) },
  { path: 'search', loadChildren: () => import('./advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule) },
  { path: 'notification', loadChildren: () => import('./notification-list/notification-list.module').then(m => m.NotificationListModule), canActivate: [AuthGuard] },
  { path: 'verify-phone', loadChildren: () => import('./verify-phone/verify-phone.module').then(m => m.VerifyPhoneModule) },
  { path: 'verify-email', loadChildren: () => import('./verify-email/verify-email.module').then(m => m.VerifyEmailModule) },
  { path: 'shorts', loadChildren: () => import('./shorts/shorts.module').then(m => m.ShortsModule) },
  { path: '**', redirectTo: '' }

];

const mainRoutes: Routes = [
  { path: 'ar', children: routes },
  { path: 'en', children: routes },
  { path: '', redirectTo: 'ar', pathMatch: 'full' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(mainRoutes, {
      initialNavigation: 'enabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
