import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { notificationModel } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notification$: BehaviorSubject<notificationModel> = new BehaviorSubject<notificationModel>(null);
  constructor() { }
  setNotification(notification: notificationModel) {
    this.notification$.next(notification)
  }
  getNotification() {
    return this.notification$.asObservable()
  }
}
