import { Component, OnInit } from "@angular/core";
import { Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { StorageID } from "../../../services/base/storage.service";
import { AuthService } from "../../../services/api/auth.service";
import { BaseService } from "../../../services/base/base.service";
import { SearchParamters, User } from "./../../../services/models/models";
import { SeoService } from "../../../services/seo/seo.service";
//import { ShortRealDetailsComponent } from "../../../shorts/short-real-details/short-real-details.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
   
  subzen: Subscription;
  selectedLang: string = "ar";
  displayLogin: boolean = false;
  displayForgetPass: boolean = false;
  isLoggedIn = false;
  isLoginFailed = false;
  user: User;
  count: number = 0;
  ProfileInfo: User = new User();

   

  constructor(
    public translate: TranslateService,
    public base: BaseService,
    public router: Router,
    private serop: SeoService,
    public authService: AuthService,
    private toastr: ToastrService,
  ) {
    var data = this.base.store.getTimeStamp<User>(StorageID.UserInfo);

    this.user = data.Data;
    //  JSON.parse(localStorage.getItem("user"));
  }

  logout() {
    this.authService.signOut();
    this.router.navigate(["/"]);
  }

  showLogin() {
    this.displayLogin = true;
  }
 

  verifyPhone() {
    this.authService.verifyPhone().subscribe((da) => {
      if (da.status == true) {
        this.toastr.success(da.message);
      }
    });
  }
  changeLang() {
    if (this.router.url.includes("en")) {
      this.translate.use("ar");
    }
  }
  // search by type
  proType: string = "";
  searchParams: SearchParamters;
  cpage: number = 1;
  searchByType(type) {
    this.proType = type;

    let queryParams: Params = {
      page: this.cpage,
      post_type: this.proType,
    };
    let search: SearchParamters = new SearchParamters(queryParams);
    this.searchParams = search;

    if (this.router.url.includes("property-list")) {
      this.router.navigate(this.base.getrouterlink("/property-list"), {
        queryParams: queryParams,
      });

      setTimeout(() => {
        if (this.base.serop.isBrowser()) {
          window.location.reload();
        }
      }, 500);
    } else {
      this.router.navigate(this.base.getrouterlink("/property-list"), {
        queryParams: queryParams,
      });
    }
  }
  getCount(e) {
    this.count = e;
  }
  closeLogin(e) {
    this.displayLogin = e;
  }
  ngOnInit() {
    //getUser()
    if (this.base.isLoggedIn) {
      // this.userData()
      var data = this.base.store.getTimeStamp<User>(StorageID.UserInfo);
      this.ProfileInfo = data.Data;
      //console.log(this.ProfileInfo);
      if (this.ProfileInfo?.need_verify_email == true) {
        // this.router.navigate(this.base.getrouterlink("/verify-email"));
      }
    }
  }
}
