import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../services/notification/notification.service';

import { notificationModel } from '../../../services/models/models';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
  showPanel: boolean;
  notification: notificationModel;
  notificationSub: Subscription;
  notificationTimeout: any;
  constructor(private notificationService: NotificationService,
    public router: Router) { }


  ngOnInit(): void {
    this.notificationService.getNotification()
      .subscribe(n => {
        this.notification = n;
        this.showPanel = n !== null;

        this.notificationTimeout = setTimeout(() => {
          this.showPanel = false;
        }, 3000);
      });
  }
  dismissNotification() {
    this.showPanel = false;
  }
  ngOnDestroy() {
    if (this.notificationSub) {
      this.notificationSub.unsubscribe();

    }
    clearTimeout(this.notificationTimeout);
  }
}
