import { Params } from "@angular/router";

export class APIResult<T> {
  success: boolean = false;
  status: boolean = false;
  data?: T;
  result?: T;
  cities?: T;
  notaries?: T;
  favorites?: T;
  district?: T;
  required?: T;
  searches?: T;
  notifications?: T;
  urgentrequests?: T;
  required_realestate?: T;
  property_extension?: T;
  room_extension?: T;
  social?: T;
  message: string = "";
  error: Error;
  errors: Error;
  additionals? = new additionals();
  unseen_count: number;
}
export class companiesResult<T> {
  company?: T;
  comments?: T;
  realestates?: T;
  contractors?: T;
  employees?: T;
  status: boolean = false;
  companies?: T;
  message: string = "";
  error: Error;
}

export class RealestateResult<T> {
  status: boolean = false;
  realestate?: T;
  realestates?: T;
  message: string = "";
  error: Error;
}

export class propertyTypeResult<T> {
  status: boolean = false;
  property_type?: T;
  message: string = "";
  error: Error;
}

export class userResult<T> {
  status: boolean = false;
  user?: T;
  message: string = "";
  error: Error;
}

export class additionals {
  rentCount: number;
  sellCount: number;
  studentHouseCount: number;
}

export class StorageWTime<T> {
  public Data: T;
  public expireDate: Date;
  public HasValue: boolean = false;
}

export interface Error {
  name: string[];
}

export class PaginationResponse<T> {
  current_page: number;
  data: T;
  notifications: T;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url?: any;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
  total: number;
}

//registeration
export class homeData {
  sell_count: number;
  rent_count: number;
  rent_2: PropertyList[];
  sell_2: PropertyList[];
}
export class Country {
  id?: number;
  admin_id?: number;
  country_name_ar?: string = "";
  country_name_en?: string = "";
  country_iso: string;
  country_code: string;
  country_flag?: any;
  country_status?: string;
  deleted_at?: any;
  created_at?: Date;
  updated_at?: Date;
}

export class City {
  id: number;
  admin_id: number;
  city_name_ar: string;
  city_name_en: string;
  country_id: number;
  city_status: string;
  created_at: Date;
  updated_at: Date;
}

export class District {
  id: number;
  admin_id: number;
  district_name_ar: string;
  district_name_en: string;
  country_id: number;
  city_id: number;
  created_at: Date;
  updated_at: Date;
}
export class ComCertPhoto {}

export class profileModule {
  id: number;
  admin_id: number;
  name: string;
  email: string;
  mobile: string;
  personal_photo: string;
  country_id?: Country = new Country();
  city_id: City = new City();
  account_type: string;
  company_id?: any;
  commercial_end_at: string;
  commercial_no: number;
  commercial_image: string;
  email_verify_code?: any;
  email_verified_at: Date;
  mobile_verify_code?: any;
  mobile_verified_at: Date;
  account_status: string;
  lat?: any;
  lng?: any;
  address?: any;
  verified_account: string;
  notify_email: string;
  last_check_end_post?: any;
  over_all: number;
  notary: string;
  reset_password_code?: any;
  fcm_id?: any;
  created_at: Date;
  updated_at: Date;
}

// Login data
export class loginData {
  email: string;
  password: string;
  fcm_id?: string;
}
export class User {
  id: number;
  name: string;
  email: string;
  fcm_id: string;
  mobile: string;
  personal_photo: string;
  country_id: number;
  city_id: number;
  account_type: string;
  company_id?: any;
  commercial_end_at: string;
  commercial_no: number;
  commercial_image: string;
  email_verified_at: Date;
  mobile_verified_at: Date;
  account_status: string;
  lat?: any;
  lng?: any;
  address?: any;
  verified_account: string;
  notify_email: string;
  over_all: number;
  notary: string;
  created_at: Date;
  token: string;
  authorization_type: string;
  need_verify_email: boolean = false;
  need_verify_mobile: boolean = false;
  reset_password_code?: any;
  updated_at: Date;
}
export class UserInfo {
  success: boolean;
  data: LoginToken;
  message: string;
}
export class LoginToken {
  user: User;
  access_token: string;
  token_type: string;
}

export class MediaModule {
  id: number;
  admin_id: number;
  user_id?: any;
  file_id?: any;
  file: string;
  full_path: string;
  type_file: string;
  type_id: string;
  path: string;
  ext: string;
  name: string;
  size: string;
  size_bytes: number;
  mimtype: string;
  deleted_at?: any;
  created_at: Date;
  updated_at: Date;
}

//property type
export class propertyType {
  id: number;
  property_name_ar: string;
  property_name_en: string;
  property_image: string;
  created_at: Date;
  updated_at: Date;
  property_status: string;
}

//rate
export class propertyRate {
  id: number;
  vlaue: string;
  name: string;
  nameAr?: string;
}
export class propertyStatus {
  id: number;
  vlaue: string;
  name: string;
  nameAr: string;
}
//rate
export class searchRooms {
  id: number;
  vlaue: string;
  name: string;
  nameAr: string;
}
//property extension

export class PropertyExt {
  id: number;
  property_extension_name_ar: string;
  property_extension_name_en: string;
  property_extension_image: string;
  created_at: Date;
  updated_at: Date;
  extention_id: PropertyExt = new PropertyExt();
}

export class PropertyUserData {
  id: number;
  name: string;
  mobile: String;
  email: string;
  rate?: number;
  constructor(rate: string) {
    this.rate = Number(rate);
  }
}

export class prop_type {
  id: number;
  prop_type_name: string;
  prop_icon: string;
  prop_status: string;
}

export class PropertyList {
  id: number;
  country_id: Country;
  city_id: City;
  district_id: District;
  address: string;
  latitude: string;
  longitude: string;
  main_image: string;
  sustainable_certificate_image: string;
  sustainable_status: string;
  property_type_id: propertyType = new propertyType();
  post_type: string;
  north: string;
  north_street_width: string;
  east: string;
  east_street_width: string;
  west: string;
  west_street_width: string;
  south: string;
  south_street_width: string;
  age_property: number;
  duration_per_day: number;
  duration_end_at: string;
  total_area_m3: number;
  bathrooms_count: number;
  rooms_count: number;
  additional_information: string;
  payment_method: string;
  price: string;
  include_tax: string;
  preview_time: string;
  preview_date_from: string;
  preview_date_to: string;
  preview_time_from: string;
  preview_time_to: string;
  additional_mobile: string;
  views: number;
  user_id: User = new User();
  employee_id: employeeModule = new employeeModule();
  complete: string;
  created_at: Date;
  updated_at: Date;
  images: MediaModule[] = [];
  rooms: studentRoomsModule[];
  extentions: PropertyExt[] = [];
  favorite?: favorite = new favorite();
}
export class favorite {
  id: number;
  real_estates_id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
}
export class propertyListPaging {
  current_page: number;
  data: PropertyList[] = [];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url?: any;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
  total: number;
}
//property by id

export class PropType {
  id: number;
  prop_type_name: string;
  prop_icon?: any;
  prop_status: string;
}

export class propertyModule {
  id: number;
  country_id: Country = new Country();
  city_id: City = new City();
  district_id: District = new District();
  address: string;
  latitude: string;
  longitude: string;
  main_image: string;
  sustainable_certificate_image?: any;
  sustainable_status?: any;
  property_type_id: propertyType = new propertyType();
  post_type: string;
  north?: any;
  north_street_width?: any;
  east?: any;
  east_street_width?: any;
  west?: any;
  west_street_width?: any;
  south?: any;
  south_street_width?: any;
  age_property: number;
  duration_per_day: number;
  duration_end_at: string;
  total_area_m3: number;
  bathrooms_count: number;
  rooms_count: number;
  additional_information: string;
  payment_method: string;
  price: number;
  include_tax: string;
  preview_time: string;
  preview_date_from?: any;
  preview_date_to?: any;
  preview_time_from?: any;
  preview_time_to?: any;
  additional_mobile?: any;
  views: number;
  user_id: User = new User();
  employee_id: employeeModule = new employeeModule();
  complete?: any;
  created_at: Date;
  updated_at: Date;
  rooms: any[];
  extentions: PropertyExt[] = [];
  weekdays?: Weekdays = new Weekdays();
  images: MediaModule[] = [];
  videos: MediaModule[] = [];
  scheme: MediaModule[] = [];
  near_by: NearBy[];
  favorite: favorite = new favorite();
}
export class Weekdays {
  id: number;
  real_estates_id: number;
  saturday: string;
  saturday_from_time: string;
  saturday_to_time: string;
  sunday: string;
  sunday_from_time: string;
  sunday_to_time: string;
  monday: string;
  monday_from_time: string;
  monday_to_time: string;
  tuesday: string;
  tuesday_from_time: string;
  tuesday_to_time: string;
  wednesday: string;
  wednesday_from_time: string;
  wednesday_to_time: string;
  thursday: string;
  thursday_from_time: string;
  thursday_to_time: string;
  friday: string;
  friday_from_time: string;
  friday_to_time: string;
  created_at: Date;
  updated_at: Date;
}
export class ExtRooms {
  room_extension_name_ar: string;
  room_extension_name_en: string;
  room_extension_image: string;
  id: number;
  real_estates_id: number;
  room_id: number;
  room_extension_id: ExtRooms = new ExtRooms();
}
export class studentRoomsModule {
  id: number;
  extentions: ExtRooms[] = [];
  real_estates_id: number;
  monthly_rent_amount: number;
  room_length: number;
  room_status: string;
  room_width: number;
  user_id: number;
  selectedStaff: any[] = [];
}
export class PreviewDays {
  id: number;
  real_estate_id: number;
  day: string;
  from: string;
  to: string;
  created_at: Date;
  updated_at: Date;
}

export class requiredModule {
  id: number;
  user_id: User = new User();
  search_by: string;
  city_id: City = new City();
  district_id?: any;
  address: string;
  geographical_area: string;
  longitude: string;
  latitude: string;
  post_type: string;
  property_type_id: propertyType = new propertyType();
  rooms_count: number;
  bathrooms_count: number;
  price_from: number;
  price_to: number;
  keywords: string;
  real_estate_required: string;
  property_extension: string;
  created_at: Date;
  updated_at: Date;
  extentions: PropertyExt[] = [];
}
export class SearchParamters {
  public page?: number = 1;
  public post_type: string = "";
  public address? = "";
  public city_id? = "";
  public property_type_id? = "";
  public price_from? = "";
  public price_to? = "";
  public rate_count? = "";
  public rooms_count? = "";
  public bathrooms_count? = "";
  public area_from? = "";
  public extentions? = [];
  public sortable?;

  constructor(public params: Params  ) {
 //   console.log('param post type is => ',params.post_type);
    this.page = params.page ?? 1;
    this.post_type = params.type??'';
    this.address = params.address ?? "";
    this.city_id = params.city ?? "";
    this.property_type_id = params.prop_type_id ?? "";
    this.price_from = params.price_from ?? "";
    this.price_to = params.price_to ?? "";
    this.rate_count = params.rate_count ?? "";
    this.rooms_count = params.rooms_count ?? "";
    this.bathrooms_count = params.bathrooms_count ?? "";
    this.area_from = params.prop_m3 ?? "";
    this.extentions = params.pext ?? '';
    this.sortable = params.sortable ?? "";
  }
}
export class AdvancedSearchParamters {
  page?: number = 1;
  search_by: string = "";
  city_id?;
  address? = "";
  geographical_area?;
  latitude?;
  longitude?;
  post_type? = "";
  property_type_id? = "";
  rooms_count? = "";
  bathrooms_count? = "";
  property_extension? = [];
  price_from? = "";
  price_to? = "";
  real_estate_required: string = "yes";
  constructor(params: Params | any) {
    this.page = params.page ?? 1;
    this.search_by = params.search_by ?? "";
    this.city_id = params.city_id ?? "";
    this.address = params.address ?? "";
    this.geographical_area = params.geographical_area ?? "";
    this.latitude = params.lat ?? "";
    this.longitude = params.lng ?? "";
    this.property_type_id = params.property_type_id ?? "";
    this.rooms_count = params.rooms_count ?? "";
    this.price_to = params.price_to ?? "";
    this.price_from = params.price_from ?? "";
    this.bathrooms_count = params.bathrooms_count ?? "";
    this.property_extension = params.property_extension ?? [];
    this.real_estate_required = params.real_estate_required ?? "";
  }
}
export class GMarkers {
  lat: any;
  lng: any;
  title: string='';
  description: string = '';
  image?: string = '';
  constructor(lat?, lng?, title?, desc?, image?: string) {
    if (lat) {
      this.lat = parseFloat(lat);
      this.lng = parseFloat(lng);
      this.title = title;
      this.description = desc;
      this.image = image; // image;
    }
  }
}

//company
export class companyModule {
  id: number;
  name: string;
  email: string;
  personal_photo: string;
  country_id?: number;
  city_id?: number;
  account_type: string;
  lat: string;
  lng: string;
  address: string;
  verified_account: string;
  over_all: number;
  notary: string;
  created_at: Date;
}
export class constructorModule {
  id: number;
  admin_id: number;
  contractor_name: string;
  contractor_company_name: string;
  contractor_mobile: number;
  contractor_website?: any;
  contractor_facebook?: any;
  contractor_twitter?: any;
  contractor_youtube?: any;
  contractor_about: string;
  contractor_commercial: string;
  contractor_verify: string;
  contractor_address: string;
  contractor_lat: string;
  contractor_lng: string;
  country_id: Country;
  city_id: City;
  price_per_m3: string;
  created_at: Date;
  updated_at: Date;
}

export class ProfileRate {
  id: number;
  real_estates_id: number;
  real_estates_user_id: number;
  request_user_id: User = new User();
  room_id: number;
  preview_date_at: string;
  preview_time_from: string;
  preview_time_to: string;
  request_status: string;
  follower_reason: string;
  refused_reason: string;
  real_estates_rate: string;
  real_estates_feedback: string;
  request_user_rate: string;
  request_user_feedback?: any;
  request_type: string;
  rate_collect_job: string;
  created_at: Date;
  updated_at: Date;
}

export class CompanyProfileModule {
  id: number;
  name: string;
  email: string;
  personal_photo: string;
  country_id: Country = new Country();
  city_id: City = new City();
  account_type: string;
  lat: string;
  lng: string;
  address?: any;
  verified_account: string;
  over_all: number;
  notary: string;
  created_at: Date;
}
// export class LoginResponse {
//     user: User;
//     access_token: string;
//     token_type: string;
// }

export class NearBy {
  id: number;
  real_estates_id: number;
  address: string;
  latitude: string;
  longitude: string;
  duration?: any;
  distance?: any;
  created_at: Date;
  updated_at: Date;
}
export class PropertyResponse {
  id: number;
  admin_id?: any;
  country_id?: Country = new Country();
  city_id?: City = new City();
  district_id?: any;
  address?: any;
  latitude?: any;
  longitude?: any;
  main_image?: any;
  sustainable_certificate_image?: any;
  sustainable_status?: any;
  property_type_id?: any;
  post_type: string;
  north?: any;
  north_street_width?: any;
  east?: any;
  east_street_width?: any;
  west?: any;
  west_street_width?: any;
  south?: any;
  south_street_width?: any;
  age_property?: any;
  duration_per_day?: any;
  duration_end_at?: any;
  total_area_m3?: any;
  bathrooms_count?: any;
  rooms_count?: any;
  additional_information?: any;
  payment_method?: any;
  price?: any;
  include_tax?: any;
  photography360?: any;
  professional_photography?: any;
  eng_design_request?: any;
  preview_time?: any;
  preview_date_from?: any;
  preview_date_to?: any;
  preview_time_from?: any;
  preview_time_to?: any;
  additional_mobile?: any;
  send_to_customer?: any;
  customer_sent_at?: any;
  views?: any;
  user_id: User = new User();
  employee_id?: any;
  complete?: any;
  graphic_email_sent: string;
  graphic_email_subject?: any;
  graphic_email_msg?: any;
  graphic_email_timestamp?: any;
  graphic_email_send_by_admin?: any;
  graphic_email_list?: any;
  created_at: Date;
  updated_at: Date;
  rooms: studentRoomsModule[] = [];
  weekdays?: any;
  extentions: PropertyExt[] = [];
  images: MediaModule[] = [];
  videos: MediaModule[] = [];
  scheme: MediaModule[] = [];
  near_by: NearBy[] = [];
}
//employee
export class employeeModule {
  id: number;
  name: string;
  email: string;
  mobile: string;
  personal_photo: string;
  country_id?: number;
  city_id?: number;
  lat: string;
  lng: string;
  address?: any;
  over_all: number;
  account_status: string;
}
export class statisticsModule {
  total_property: number;
  total_property_rent_active: number;
  total_property_rent_ended: number;
  total_property_sell_active: number;
  total_property_sell_ended: number;
  total_property_student_active: number;
  total_property_student_ended: number;
  total_order_sell_accept: number;
  total_order_sell_pending: number;
  total_order_sell_refused: number;
  total_order_rent_accept: number;
  total_order_rent_pending: number;
  total_order_rent_refused: number;
  total_order_student_accept: number;
  total_order_student_pending: number;
  total_order_student_refused: number;
  all: number;
  all_rent: number;
  all_rent_active: number;
  all_rent_ended: number;
  all_sale: number;
  all_sale_active: number;
  all_sale_ended: number;
  all_shared: number;
  all_shared_active: number;
  all_shared_ended: number;
}
//requests
export class Owner {
  id: number;
  name: string;
}
export class requestModule {
  id: number;
  real_estates_id: RealEstatesId = new RealEstatesId();
  real_estates_user_id: User = new User();
  request_user_id: User = new User();
  room_id: studentRoomsModule = new studentRoomsModule();
  preview_date_at: string;
  preview_time_from: string;
  preview_time_to: string;
  request_status: string;
  follower_reason?: any;
  refused_reason: string;
  real_estates_rate?: any;
  real_estates_feedback?: any;
  request_user_rate?: any;
  request_user_feedback?: any;
  request_type: string;
  rate_collect_job: string;
  created_at: Date;
  updated_at: Date;
}
export class RealEstatesId {
  id: number;
  address: string;
  latitude: string;
  post_type: string;
  longitude: string;
  user_id: number;
  employee_id: number;
}
export class customerModule {
  id: number;
  admin_id?: any;
  customer_name: string;
  customer_mobile: string;
  customer_email: string;
  customer_type: string;
  company_id: companyModule = new companyModule();
  employee_id?: any;
  created_at: Date;
  updated_at: Date;
}
export class Directions {
  id: number;
  real_estate_id: number;
  north_check: string;
  north: string;
  east_check: string;
  east: string;
  west_check: string;
  west: string;
  south_check: string;
  south: string;
  created_at: Date;
  updated_at: Date;
}

export class myPropertyList {
  id: number;
  country_id: Country;
  city_id: City;
  district_id: District;
  address: string;
  latitude: string;
  longitude: string;
  main_image: string;
  sustainable_certificate_image: string;
  sustainable_status: string;
  property_type_id: propertyType = new propertyType();
  post_type: string;
  north: string;
  north_street_width: string;
  east: string;
  east_street_width: string;
  west: string;
  west_street_width: string;
  south: string;
  south_street_width: string;
  age_property: number;
  duration_per_day: number;
  duration_end_at: string;
  total_area_m3: number;
  bathrooms_count: number;
  rooms_count: number;
  additional_information: string;
  payment_method: string;
  price: string;
  include_tax: string;
  preview_time: string;
  preview_date_from: string;
  preview_date_to: string;
  preview_time_from: string;
  preview_time_to: string;
  additional_mobile: string;
  views: number;
  user_id: User = new User();
  employee_id: employeeModule = new employeeModule();
  complete: string;
  created_at: Date;
  updated_at: Date;
  images: MediaModule[] = [];
  rooms: studentRoomsModule[];
  extentions: PropertyExt[] = [];
  favorite?: favorite = new favorite();
}

//property requests
export class propertyRequestModule {
  id: number;
  user: User;
  real_estate_id: number;
  created_at: Date;
  post_type: string;
  pro_type: prop_type;
  status: string;
  refused_reason: string;
  processing_reason: string;
}

export class urgentRequestModule {
  id: number;
  admin_id: number;
  company_id: companyModule = new companyModule();
  employee_id: employeeModule = new employeeModule();
  request_title: string;
  request_content: string;
  request_status: string;
  employee_feedback: string;
  created_at: Date;
  updated_at: Date;
}
//saved
export class savedSearchModule {
  id: number;
  user_id: User = new User();
  search_by: string;
  city_id?: any;
  district_id?: any;
  address: string;
  geographical_area: string;
  longitude: string;
  latitude: string;
  post_type: string;
  property_type_id: propertyType = new propertyType();
  rooms_count: number;
  bathrooms_count: number;
  price_from: string;
  price_to: string;
  keywords?: any;
  real_estate_required: string;
  property_extension: string;
  created_at: Date;
  updated_at: Date;
  extentions: PropertyExt[];
}
export class advancedSearchModule<T> {
  realestates_count: number;
  realestates: T;
}
export class Realestates<T> {
  current_page: number;
  data: T;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
  total: number;
}
export class daysModule {
  id: number;
  val: string;
  name: string;
  nameAr: string;
  from: string;
  to: string;
  select: boolean;
}
export class notificationModule {
  id: number;
  real_estate_id: number;
  request_id: number;
  seen_type: string;
  message: string;
}

export class favoriteModule {
  id: number;
  real_estates_id: PropertyList = new PropertyList();
  user_id: number;
  created_at: Date;
  updated_at: Date;
}
export class register {
  commercial_end_at: string;
  commercial_no: string;
  name: string;
  email: string;
  mobile: string;
  account_type: string;
  notary: string;
  commercial_image: string;
  accept_terms: string;
  fcm_id?: any;
  token?: string;
  authorization_type?: string;
  need_verify_email?: boolean;
  need_verify_mobile?: boolean;
}
export class contractorsparam {
  page?: number = 1;
  name?: string = "";
  mobile?: string = "";
  address?: string = "";
  country_id?: number = 0;
  city_id?: number = 0;
}
export class notificationModel {
  body: string;
  title: string;
}
export class SocialModel {
  id: number;
  social_name: string;
  social_image: string;
  social_url: string;
  social_target: string;
  created_at: Date;
  updated_at: Date;
}
