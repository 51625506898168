import { Injectable } from '@angular/core';
import { daysModule, propertyRate } from '../models/models';
import { propertyStatus } from '../../services/models/models';
import { searchRooms } from './../models/models';

@Injectable()
export class StaticService {
  public rates: propertyRate[] = [
    { id: 1, vlaue: '', name: 'Any', nameAr: 'الكل' },
    { id: 2, vlaue: '1', name: '1', nameAr: '1' },
    { id: 3, vlaue: '2', name: '2', nameAr: '2' },
    { id: 4, vlaue: '3', name: '3', nameAr: '3' },
    { id: 5, vlaue: '4', name: '4', nameAr: '4' },
    { id: 6, vlaue: '5', name: '5', nameAr: '5' }
  ]
  public PurchaseHeader: any = [
    { id: 1, vlaue: 'all', name: 'All', nameAr: 'الكل' },
    { id: 2, vlaue: 'pending', name: 'Pending', nameAr: 'بالانتظار' },
    { id: 3, vlaue: 'accept', name: 'Accepted', nameAr: 'مقبول' },
    { id: 4, vlaue: 'refused', name: 'Rejected', nameAr: 'مرفوض' }
  ]
  public myAdsHeader: any = [
    { id: 1, vlaue: 'all', name: 'All', nameAr: 'الكل' },
    { id: 2, vlaue: 'pending', name: 'Active', nameAr: 'النشط' },
    { id: 3, vlaue: 'accept', name: 'Expired', nameAr: 'المنتهي' },
  ]

  public Propertystatus: propertyStatus[] = [
    { id: 1, vlaue: '', name: 'All', nameAr: 'الكل' },
    { id: 2, vlaue: 'shared', name: 'shared spaces', nameAr: 'مساحات مشتركة' },
    { id: 3, vlaue: 'sale', name: 'sale', nameAr: 'بيع' },
    { id: 4, vlaue: 'rent', name: 'rent', nameAr: 'شراء' },
  ]
  public sendTo: propertyStatus[] = [
    { id: 1, vlaue: 'all', name: 'all', nameAr: 'الكل' },
    { id: 2, vlaue: 'shared', name: 'shared', nameAr: ' مساحات مشتركة' },
    { id: 3, vlaue: 'sale', name: 'sale', nameAr: 'بيع' },
    { id: 4, vlaue: 'rent', name: 'rent', nameAr: 'شراء' },
  ]

  public roomsNumber: searchRooms[] = [
    { id: 1, vlaue: '', name: 'Any', nameAr: 'الكل' },
    { id: 2, vlaue: '1', name: '+1 ', nameAr: '+1' },
    { id: 3, vlaue: '2', name: '+2', nameAr: '+2' },
    { id: 4, vlaue: '3', name: '+3', nameAr: '+3' },
    { id: 5, vlaue: '4', name: '+4', nameAr: '+4' },
    { id: 6, vlaue: '5', name: '+5', nameAr: '+5' }

  ]
  public geokm = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 10 },
    { id: 7, value: 15 },
    { id: 8, value: 20 },
    { id: 9, value: 25 },
    { id: 10, value: 30 }
    //{ id: 11, value: 35 }

  ]
  public nearestGeo = [
    { id: 1, value: 50 },
    { id: 2, value: 100 },
    { id: 3, value: 200 },
    { id: 4, value: 300 },
    { id: 5, value: 400 },
    { id: 6, value: 500 },
  ]

  public bathRooms: searchRooms[] = [
    { id: 1, vlaue: '', name: 'Any', nameAr: 'الكل' },
    { id: 2, vlaue: '1', name: '+1 ', nameAr: '+1' },
    { id: 3, vlaue: '2', name: '+2', nameAr: '+2' },
    { id: 4, vlaue: '3', name: '+3', nameAr: '+3' },
    { id: 5, vlaue: '4', name: '+4', nameAr: '+4' },
    { id: 6, vlaue: '5', name: '+5', nameAr: '+5' }

  ]
  public Rooms = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },
    { id: 12, value: 12 },
    { id: 13, value: 13 },
    { id: 14, value: 14 },
    { id: 15, value: 15 },
    { id: 16, value: 16 },
    { id: 17, value: 18 },
    { id: 19, value: 19 },
    { id: 20, value: 20 }

  ]
  public weekDays: daysModule[] = [
    { id: 1, val: 'saturday', name: 'Saturday', nameAr: 'السبت', from: '12:00 AM', to: '1:00 PM', select: false },
    { id: 2, val: 'sunday', name: 'Sunday', nameAr: 'الأحد', from: '12:00 AM', to: '1:00 PM', select: false },
    { id: 3, val: 'monday', name: 'Monday', nameAr: 'الأثنين', from: '12:00 AM', to: '1:00 PM', select: false },
    { id: 4, val: 'tuesday', name: 'Tuesday', nameAr: 'الثلاثاء', from: '12:00 AM', to: '1:00 PM', select: false },
    { id: 5, val: 'wednesday', name: 'Wednesday', nameAr: 'الأربعاء', from: '12:00 AM', to: '1:00 PM', select: false },
    { id: 6, val: 'thursday', name: 'Thursday', nameAr: 'الخميس', from: '12:00 AM', to: '1:00 PM', select: false },
    { id: 7, val: 'friday', name: 'Friday', nameAr: 'الجمعة', from: '12:00 AM', to: '1:00 PM', select: false }
  ]
  public skeleton = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 }
  ]
 
}