import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSharedModule } from './app.shared.module';
import { HttpErrorHandler } from './services/httperror.service';

import { HttpClientModule } from '@angular/common/http';
import { AppTranslationModule } from './app.translation.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpIterceptorProviders } from './services/interceptors/index';
import { APIService } from './services/api/api.service';
import { StorageService } from './services/base/storage.service';
import { AuthService } from './services/api/auth.service';
import { LocaleService } from './services/localization/locale.service';
import { SeoService } from './services/seo/seo.service';
import { BaseService } from './services/base/base.service';
import { ServerOptionService } from './services/base/server-option.service';
import { StaticService } from './services/base/static.service';
import { AuthGuard } from './services/guard/auth.guard';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';

//import { AngularFireMessagingModule } from '@angular/fire/messaging';
//import { AngularFireModule } from '@angular/fire';

import { ThemeModule } from './shared/layout/theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export const firebaseConfig = {
  apiKey: "AIzaSyBLttIeS2yYuftGm3dftRNCTTIEtsmL_NM",
  authDomain: "msaakn-305721.firebaseapp.com",
  projectId: "msaakn-305721",
  storageBucket: "msaakn-305721.appspot.com",
  messagingSenderId: "461748385821",
  appId: "1:461748385821:web:8748d575e951e6b6132ef4",
  measurementId: "G-5G0MR7C373"
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'msaakn-app' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    HttpClientModule,
    AppTranslationModule,
    AppSharedModule,
    AppRoutingModule,
    RouterModule,
    //AngularFireMessagingModule,
    ThemeModule,
    NgbModule,

   // AngularFireModule.initializeApp(firebaseConfig)
  ],
  providers: [
    httpIterceptorProviders,
    APIService,
    Title,
    MessageService, ConfirmationService,
    StorageService,
    AuthService,
    AuthGuard,
    LocaleService,
    HttpErrorHandler, DatePipe,
    SeoService,
    BaseService,
    ServerOptionService,
    StaticService,
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: 'SessionStorage', useFactory: getSessionStorage },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
export function getLocalStorage() {
  return typeof window !== 'undefined' ? window.localStorage : null;
}

export function getSessionStorage() {
  return typeof window !== 'undefined' ? window.sessionStorage : null;
}
