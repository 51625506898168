import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaPipe } from './media.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [MediaPipe],
    exports: [MediaPipe],
    providers: [MediaPipe]
})
export class MediaPipeModule { }