import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './../api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private user: AuthService, private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.user.isLoggedIn()) {
      if (next!.url[0].path == 'signup' || next!.url[0].path == 'login') {
        return true;
      }
      else {
        this.router.navigate(['/']);
        return false;
      }
    } else {
      if (next!.url[0].path == 'signup' || next!.url[0].path == 'login') {
        this.router.navigate(['/']);
        return false;
      }
      else {
        return true;
      }
    }
  }

}
