import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'media', pure: true })
export class MediaPipe implements PipeTransform {
  constructor() { }

  transform(url: string = ''): string {
    return 'https://api.msaakn.com/storage/'+url;
  }
}
