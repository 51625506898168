import { Component, HostListener, OnInit } from '@angular/core';
import { Params,Router } from '@angular/router';
import { BaseService } from '../../../services/base/base.service';
import { AuthService } from '../../../services/api/auth.service';
import { SocialModel } from '../../../services/models/models';
import { SearchParamters } from "./../../../services/models/models";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 100;
  socialList: SocialModel[] = [];
  constructor(public router: Router, public base: BaseService, private authService: AuthService) { }
  @HostListener('window:scroll')
  checkScroll() {
    if (this.base.serop.isBrowser()) {

      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      if (scrollPosition >= this.topPosToStartShowing) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
  }
  gotoTop() {
    if (this.base.serop.isBrowser()) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

  }
  ngOnInit(): void {
    this.authService.socialLinks().subscribe((da: SocialModel[]) => {
      this.socialList = da
    })
  }


  // search by type
  proType: string = "";
  searchParams: SearchParamters;
  cpage: number = 1;
  searchByType(type) {
    this.proType = type;

    let queryParams: Params = {
      page: this.cpage,
      post_type: this.proType,
    };
    let search: SearchParamters = new SearchParamters(queryParams);
    this.searchParams = search;

    if (this.router.url.includes("property-list")) {
      this.router.navigate(this.base.getrouterlink("/property-list"), {
        queryParams: queryParams,
      });

      setTimeout(() => {
        if (this.base.serop.isBrowser()) {
          window.location.reload();
        }
      }, 500);
    } else {
      this.router.navigate(this.base.getrouterlink("/property-list"), {
        queryParams: queryParams,
      });
    }
  }

}
