import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NavbarModule } from '../navbar/navbar.module';
import { FooterModule } from '../footer/footer.module';




@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DialogModule,
    TranslateModule,
    FooterModule,
    NavbarModule,
  ],
  exports: [
    DialogModule,
    FooterModule,
    NavbarModule
  ]
})
export class ThemeModule { }
