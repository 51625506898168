import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { loginData, User, UserInfo, PaginationResponse, notificationModule, userResult, profileModule, propertyModule, SocialModel } from '../models/models';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Apimethods, APIService, APIVS } from './api.service';
import { APIResult } from './../models/models';
import { StorageID, StorageService, StorageType } from './../base/storage.service';
import { BaseService } from '../base/base.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User;
  loggedIn: boolean = false;
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  private _userinfosource = new BehaviorSubject<UserInfo>(undefined);

  constructor(private storage: StorageService, private api: APIService, private toastr: ToastrService, private base: BaseService) {

  }


  login(userData: loginData) { 
    return this.api.post(APIVS.V1, Apimethods.Login, true, userData).pipe(
      map((res: APIResult<User>) => {
        if (res.status == true) {
          this.toastr.success(res.message)
          //this.storage.set(StorageType.LOCALSTORAGE, StorageID.UserAuthToken, res.data.token);
          this.storage.setWithTimeStamp<string>(StorageID.UserAuthToken, res.data.token);
          this.storage.setWithTimeStamp<User>(StorageID.UserInfo, res.data);
          //this.storage.set(StorageType.LOCALSTORAGE, StorageID.UserInfo, JSON.stringify(res.data), true);
          this.user = res.data;
          // console.log(res.data);
          // console.log(this.user);
          localStorage.setItem('user', JSON.stringify(res.data))

          return res;
        }
      })
    );
  }

/*

@Mahmoud Ibrahim 
@make this function to define user info from localestorage
@get all user information here
*/
 getUser(){
     return JSON.parse(localStorage.getItem("user"));
 }

   

  getUserInfo() {
    return this.user
  }


  getProfile() {
    return this.api.get<userResult<profileModule>>(APIVS.V1, Apimethods.profile, {}).pipe(
      map((res: userResult<profileModule>) => {
        if (res.status == true) {
          return res.user
        }
      })
    )
  }

  // reset password 
  resetPassword(data) {
    return this.api.post<APIResult<any>>(APIVS.V1, Apimethods.resetPassword, true, data).pipe(
      map((res) => {
        if (res.status == true) {
          return res;
        }
        //}
      })
      // catchError(this.handleError('loginuser', []))
    );
  }
  // reset Password Code 
  resetPasswordCode(data) {
    return this.api.post<APIResult<any>>(APIVS.V1, Apimethods.resetCode, true, data).pipe(
      map((res) => {
        if (res.status == true) {
          return res;
        }
        //}
      })
      // catchError(this.handleError('loginuser', []))
    );
  }
  //update photo
  updatePhoto(data) {
    return this.api.post<APIResult<any>>(APIVS.V1, Apimethods.updatePhoto, true, data).pipe(
      map((res: APIResult<any>) => {
        return res
      })
    )
  }
  //change paswword
  ChangePassword(data) {
    return this.api.post<APIResult<any>>(APIVS.V1, Apimethods.changePassword, true, data).pipe(
      map((res: APIResult<any>) => {
        return res
      })
    )
  }
  //update account
  updateAccount(data) {
    return this.api.post<APIResult<profileModule>>(APIVS.V1, Apimethods.accountUpdate, true, data).pipe(
      map((res: APIResult<profileModule>) => {
        return res
      })
    )
  }
  //notification
  GetNotification(page, lang) {
    return this.api.get<APIResult<PaginationResponse<notificationModule[]>>>(APIVS.V1, Apimethods.notification, { page, lang }).pipe(
      map((res: APIResult<PaginationResponse<notificationModule[]>>) => {
        if (res.status == true) {
          // this.props.next(res.data);
          return res
        }
        return new PaginationResponse<notificationModule[]>();
      })
    )
  }
  //seen notification
  notificationStatus(id, seen_type) {
    return this.api.post<APIResult<any>>(APIVS.V1, Apimethods.notificationStatus, true, { id, seen_type }).pipe(
      map((res: APIResult<any>) => {
        return res
      })
    )
  }
  //unseen notification
  allNotification(seen_type) {
    return this.api.post<APIResult<any>>(APIVS.V1, Apimethods.allNotification, true, { seen_type }).pipe(
      map((res: APIResult<any>) => {
        return res
      })
    )
  }
  //verify
  verifyPhone() {
    return this.api.post<APIResult<any>>(APIVS.V1, Apimethods.verifyPhoneCode, false, {}).pipe(
      map((res: APIResult<any>) => {
        return res
      })
    )
  }
  verifyEmail() {
    return this.api.post<APIResult<any>>(APIVS.V1, Apimethods.verifyEmailCode, false, {}).pipe(
      map((res: APIResult<any>) => {
        return res
      })
    )
  }
  //social links
  socialLinks() {
    return this.api.get<APIResult<SocialModel[]>>(APIVS.V1, Apimethods.socialLinks, {}).pipe(
      map((res: APIResult<SocialModel[]>) => {
        if (res.status) {
          return res.social
        }
      })
    )
  }
  //verify code
  verifyCode(data) {
    return this.api.post<APIResult<User>>(APIVS.V1, Apimethods.code, true,data).pipe(
      map((res: APIResult<User>) => {
        //console.log(data);
         var userData = this.base.store.getTimeStamp<User>(StorageID.UserInfo);

        // Update Mobile Or Email Field if correct code
        if(data.type == 'email'){
          userData.Data.need_verify_email = false;
        }else if(data.type == 'mobile'){
          userData.Data.need_verify_mobile = false;
        }
        console.log(userData.Data);
        this.storage.setWithTimeStamp<User>(StorageID.UserInfo, userData.Data);

        return res
      })
    )
  }
  //is logged in 

  isLoggedIn() {
    let toke = this.storage.isLoggedIn();
    if (toke) {
      return true;
    }
    return false;
  }

  getAccountType() {
    let toke = JSON.parse(this.storage.get(StorageType.LOCALSTORAGE, StorageID.UserInfo, true)) as User;
    return toke.account_type;
  }

  logout() {
    return this.api.post(APIVS.V1, Apimethods.logout, true, {}).pipe(
      map((res: any) => {
        return res
      })
    )
  }


  register(data) {
    return this.api.post<any>(APIVS.V1, Apimethods.register, true, data).pipe(
      map((res: any) => {
        return res;
      }));
  }
  contractorRregister(data) {
    return this.api.post<any>(APIVS.V1, Apimethods.contractorsCreate, true, data).pipe(
      map((res: any) => {
        return res;
      }));
  }

  signOut() {
    if (this.base.serop.isBrowser()) {
      this.logout().subscribe();
      // this.storage.setWithTimeStamp<string>(StorageID.UserAuthToken, {});
      // this.storage.setWithTimeStamp<User>(StorageID.UserInfo, {});
      localStorage.clear();
      window.sessionStorage.clear();
      this.storage.clearAll();
      this.loggedIn = false;
      this._authNavStatusSource.next(false);
      this.user = undefined;
      this._userinfosource.next(undefined);
    }

  }
}



